/_ SOCIAL STUFF _/
.social-container {
  padding: 25px 50px;
}
a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

a.github-social {
  padding-right: 25px;
  color: #6f42c1;
}

a.instagram-social{
  padding-left: 25px;
  color: #FF00FF;
}
/_ Slider _/

.slider{
  border: 1px solid red;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.slide{
  border: 1px solid blue;
  width: 100%;
  height:8;
}
